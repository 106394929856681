<template>
  <b-row
    align-h="center"
    align-v="center"
  >
    <b-col
      md="5"
      class="instructions text-center text-lg-left mb-sm-3 mb-md-0"
    >
      <b-img
        :src="require('@/assets/images/svg/scan_qr.svg')"
      />
      <h4>
        Open
        <b-img
          :src="require('@/assets/images/logo/muslim-do-logo.png')"
          height="50px"
        />
        App to get the QR
      </h4>
      <ol>
        <li>
          Tap Menu <feather-icon
            icon="MenuIcon"
            class="cyan"
            size="20"
          />
          and then choose <span class="font-weight-bold cyan">Calendar</span>
        </li>
        <li>Choose <span class="cyan font-weight-bold">Booking List</span> and select View details</li>
      </ol>
    </b-col>

    <b-col md="6">
      <h1 class="text-center mb-2">
        Scan QR Code in the app
      </h1>
      <qrcode-stream
        :camera="camera"
        @decode="onDecode"
        @init="onInit"
      >
        <div
          v-show="showScanConfirmation"
          class="scan-confirmation"
        >
          <feather-icon
            icon="CheckIcon"
            size="100"
            class="green"
          />
        </div>
      </qrcode-stream>
    </b-col>

    <b-col
      class="border-top mt-1 pt-2 p-md-4"
    >
      <b-card header="Or enter scanned code">
        <b-row align-h="center">
          <b-col cols="12">
            <text-input
              id="code"
              name="Code"
              placeholder="Paste QR Code Here"
              :text.sync="result"
            />
          </b-col>
          <b-col md="2">
            <b-button
              variant="primary"
              @click="navigateConfirmAttendance"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import TextInput from '@/common/components/common/FormInputs/TextInput.vue'

export default {
  name: 'ScanQR',
  components: { QrcodeStream, TextInput },

  data() {
    return {
      camera: 'auto',
      result: null,
      showScanConfirmation: false,
    }
  },
  methods: {
    async onInit(promise) {
      try {
        await promise
      } finally {
        this.showScanConfirmation = this.camera === 'off'
      }
    },
    async onDecode(content) {
      this.result = content

      this.pause()
      await this.timeout(500)
      this.unpause()
      this.navigateConfirmAttendance()
    },
    navigateConfirmAttendance() {
      this.$router.push({ name: 'quick-event-confirm-attendance', params: { QRCode: this.result } })
    },
    unpause() {
      this.camera = 'auto'
    },

    pause() {
      this.camera = 'off'
    },

    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.instructions {
  ol{
    margin-top:1.5em;
    li {
      font-size: 1.3em;
    }
  }
}
.green{
  color:green
}
.cyan {
  color:#1c8187
}
</style>
